import React from 'react';
import Webcam from "react-webcam";
import axios from 'axios'


const videoConstraints = {
  width: 1920,
  height: 1080,
  facingMode: "user"
};

const urls = [
  'https://api.creative-lab.online/api/v1',
  'http://127.0.0.1:8000/api/v1'
]

const baseURL = urls[0]

const request = axios.create({
        baseURL: baseURL,
        timeout: 5000,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
    })

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height
  };
}

const App = () => {
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    async (hidden: boolean) => {
      // @ts-ignore
      const imageSrc = webcamRef.current.getScreenshot();
      const id = window.location.pathname.replace('/', '').replace('/', '')
      const data = new FormData()
      data.append('image', imageSrc)
      if (hidden) data.append('hidden', '1')
      const url = `${baseURL}/main/tempmessages/${id}/`
      await request.patch(
        url,
        data
      )
      window.close()
    },
    [webcamRef]
  );

  const {height, width} = getWindowDimensions()
  return (
    <>
      <Webcam
        audio={false}
        height={height}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={width}
        videoConstraints={videoConstraints}
      />
      <div style={{position: 'absolute', top: '85%', left: '45%'}}>
        <button style={{margin: 5, fontSize: 20}} onClick={() => capture(false)}>фото</button>
        <button style={{margin: 5, fontSize: 20}} onClick={() => capture(true)}>скрытое фото</button>
      </div>

    </>
  );
};

export default App
